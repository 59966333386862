
main.project(
  :class="(show ? 'show' : '') + ' transition' + transition"
  :style="computedBlendModeStyle"
)
  .image-wrapper(:style="computedImageWrapperStyle")
    .image(:style="computedImageStyle")
  .infos(:style="computedInfosStyle")
    a(:href="theProject.url" target="_blank")
      h1 {{ theProject.title }} - {{ theProject.type }}
        inline-svg(:src="svgNewWindow")

    h2(v-for="infoLine in theProject.techInfos") {{ infoLine }}
