
main.about(:class="(show ? 'show' : 'hide') + (trueHide ? ' true-hide' : '')")
  .summary
    h1
      | Welcome to Memenatis!
      br
      | A cryptocurrency veiled in mystery by the elusive Illuminati.
    h2
      | Are you bold enough to join the select few who dare to venture where others fear? Once you enter the realm of Memenatis, there's no looking back. Brace yourself to unravel the blockchain universe's with Memenatis, the Illuminati's prized secret.
    h3
      a.scroll-invite(href="/projects") Time to start unraveling...
