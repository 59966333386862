
main.roadmap(
  :class="(show ? 'show' : 'hide') + (trueHide ? ' true-hide' : '')"
)
  .mask
  #iscroll.wrapper
    ul.content
      li.item
        .date
          .year April 2024
        .thing
          .occupation Deploy Website
          .location
            a(href="https://memenatis.xyz" target="_blank") Memenatis.xyz | 
             // inline-svg(:src="svgNewWindow")
            | Memenatis
      li.item
        .date
          .year April 2024
        .thing
          .occupation Deploy Contract
          .location
            a(href="https://ftmscan.com/" target="_blank") FTMScan.com | 
              //inline-svg(:src="svgNewWindow")
            | FantomScan
      li.item
        .date
          .year April 2024
        .thing
          .occupation Launch Token
          .location
            a(href="https://spooky.fi" target="_blank") Spooky.fi | 
              //inline-svg(:src="svgNewWindow")
            | SpookySwap &nbsp;&nbsp; - 
            a(href="https://equalizer.exchange/" target="_blank") Equalizer.exchange | 
              //inline-svg(:src="svgNewWindow")
            | Equalizer
      li.item
        .date
          .skill-tag Future
        .thing
          span.category(v-for="category in skillsFuture")
            span.type {{ category.type }}
            span.skills
              span(v-for="skill in category.content") {{ skill + ' |  ' }}
      li.item
